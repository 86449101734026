import React from 'react'
import styled from 'styled-components'
import {navigate} from "gatsby";
import headerLogo from '../../../static/images/logo/logo.svg'
import Button from "../library/Button.js";
import H1 from '../library/H1.js'
import H2 from '../library/H2.js'
import Body2 from '../library/Body2.js'

const NotFound = ({strapiNotFoundPage}) => {
  
    return (
        <NotFoundStyle>
            <Left>
              <H1>{strapiNotFoundPage.title}</H1>
              <H2>{strapiNotFoundPage.subtitle}</H2>
              <Body2>{strapiNotFoundPage.subtitleSmall}</Body2>
              <Button label={strapiNotFoundPage.button} onClick={() => {navigate('/')}}/>
            </Left>
            <Right />
        </NotFoundStyle>
    )
}

const NotFoundStyle = styled.div`
    min-height: calc(100vh - 5rem);
    display: grid;
    justify-items: center;
    justify-content: center;
    align-content: center;
    margin-top: -30px;
    `
const Left = styled.div`
  text-align: center;
  * {
    margin-bottom: 3rem;
  }
`
const Right = styled.div`
    width: 50%;
    background-image: url(${headerLogo});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
`

export default NotFound
