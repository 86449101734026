import React from 'react';
import styled from 'styled-components'

const H1 = ({children}) => {
  return (
    <H1Styled>
      {children}
    </H1Styled>
  );
};

export default H1;

const H1Styled = styled.h1`
  font-size: 5rem;
  line-height: 7rem;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: 700;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-family: ${({ theme }) => theme.fonts.bold};
    line-height: 2.9rem;
    font-size: 2.4rem;
    font-weight: 700;
  }
`
