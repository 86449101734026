import React from 'react';
import styled from 'styled-components'

const Button = (props) => {
    return (
        <ButtonStyled
            type={props.type}
            onClick={props.onClick}>
            {props.label}
        </ButtonStyled>
    );
};

export default Button;

const ButtonStyled = styled.button`
  cursor: pointer;
  border-radius: 1.2rem;
  display: inline-block;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  line-height: 2.4rem;
  font-size: 1.6rem;
  padding: 0rem 4rem;
  border: none;
  height: 5rem;
  &:hover {
    transition-duration: 0.4s;
    background-color: #55546E;
    border: #55546E;
    color: ${({theme}) => theme.colors.white};
  }
`
